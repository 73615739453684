import React from 'react';

export const About = () => {
  return (
    <main id="about" className="about">
      <h1 className="about-heading">A little bit about me.</h1>
      <section className="about-info">
        <p>
          I am a military spouse currently stationed in Eglin, Florida with our
          small, but growing family. My journey into software engineering
          stemmed from a desire to utilize both my creativity and love for
          learning on a daily basis. When I code I feel like Sherlock Holmes,
          hunting down bugs and solving the issue while simultaneously feeling
          like Leonardo Da Vinci as I style and craft dynamic web solutions that
          enhance user experience and maximize operational effectiveness. As
          most of my roles have been remote, I love working from anywhere and
          can ramp up to speed on new code bases rather swiftly.
        </p>
        <p>
          I have work experience in both front end and back end although most of
          my professional experience has been on the front end, as I love
          creating magic and utilize that passion for fun and user-friendly
          intuitive code. eager to learn, constantly expanding my repertoire for
          the ever-growing industry. My newest endeavors include AI and machine
          learning in my projects and I would love to join your team in a full
          stack, front-end, or back-end role.
        </p>
      </section>
    </main>
  );
};

export default About;
