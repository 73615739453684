import Hero from '../components/hero';
import About from '../components/about';

const HomePage = () => {
  return (
    <>
      <Hero />
      <About />
    </>
  );
};

export default HomePage;
