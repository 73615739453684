import React from 'react';
import HeroImg from '../../images/workplace-white-office-with-blank-wall.jpg';

const Hero = () => {
  return (
    <section id="hero" className="hero">
      <div className="hero-container">
        <div className="hero-profile">
          <img
            className="hero-img"
            alt="Hero workspace with a computer in the background"
            src={HeroImg}
          />
          <div className="hero-content">
            <title className="hero-text">
              <p><span className='color-change'>Hi,</span> I am a <span id='cycle'></span> developer.</p>
            </title>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
