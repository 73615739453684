import React from 'react';

const Text = ({ value }) => (
  <div className="paper-text">
    {value.split('').map((char, i) => (
      <>
        {char === ' ' && <div className="space" />}
        {char !== ' ' && (
          <div className="letter" style={{ '--delay': `${i * 0.2}s` }}>
            <span className="source">{char}</span>
            <span className="shadow">{char}</span>
            <span className="overlay">{char}</span>
          </div>
        )}
      </>
    ))}
  </div>
);

const ComingSoon = () => {
  return (
    <div className="coming-soon">
      <Text value="COMING" />
      <Text value="SOON!" />
      Hi
    </div>
  );
};

export default ComingSoon;
