import Header from './components/navigation/header';
import Footer from './components/navigation/footer/index.js';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage.js';
import SkillsPage from './pages/SkillsPage.js';
import ProjectsPage from './pages/ProjectsPage.js';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/skills" element={<SkillsPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
