import React from 'react';
import ContactImg from '../../../images/Christina Ealey.jpeg';
import GmailImg from '../../../images/gmail.svg';
import LinkedInImg from "../../../images/linkedin.svg";
import GithubImg from "../../../images/github.svg";

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <section className="contact-img-container">
        <div>
          <img className="contact-img" alt="Christina Ealey" src={ContactImg} />
        </div>
      </section>
      <section className="contact-info">
        <div className="email-container">
          <h3>Like what you see?</h3>
          <h5>Let's meet and discuss future collaboration.</h5>
          <h6 className="email-info">
            <a href="mailto:ealeyc92@gmail.com">
              <img className="mail-icon" alt="G-mail" src={GmailImg} /> ealeyc92
              [@] gmail.com
            </a>{' '}
          </h6>
        </div>
        <div className="social-container">
          <h5>Or, find me on the interwebs</h5>
          <div className="icon-container">
            <a href='https://github.com/TinaEaley'> <img alt="Github logo" src={GithubImg} /></a>
            <a href='https://www.linkedin.com/in/tinaealey/'><img alt="LinkedIn logo" src={LinkedInImg} /></a>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
