import React from "react";

const Header = () => {
    return (
        <header className="header">
            <div className="header-logo">  
                <a href="/"><h3 className="header-name">Christina Ealey</h3></a>
            </div>
            <nav className="header-container">
                <div className="nav-item"><a href="/#about">About</a></div>
                <div className="nav-item"><a href="/projects">Projects</a></div>
                <div className="nav-item"><a href="/skills">Skills</a></div>
            </nav>
        </header>
    )
}

export default Header;