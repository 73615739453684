import React from 'react';
import ComingSoon from '../navigation/coming-soon';

const Skills = () => {
  return (
    <ComingSoon />
    // <section className="skills">
    //   <div className="skills-container">
    //     <div className="first-trio">
    //       <img alt="HTML Icon " src={HTMLIcon} />
    //       <h6>Hand-Coded HTML</h6>
    //       <p>
    //         My focus is writing clean, well formatted, semantic HTML5 by hand to
    //         make sure that the content is easy to read, easy to collaborate,
    //         trouble-shoot and accessible.
    //       </p>
    //     </div>
    //     <div className="second-trio">
    //       <img alt="HTML Icon " src={CSSIcon} />
    //       <h6>Well-Organized CSS</h6>
    //       <p>
    //         I pride myself on writing CSS that is easy to read and build on. I
    //         focus on keeping my CSS lean and fast to load, and I make it a habit
    //         to stay up to date on current best practices.
    //       </p>
    //     </div>
    //     <div className="third-trio">
    //       <img alt="HTML Icon " src={FigmaIcon} />
    //       <h6>Ease Converting Designs into Code</h6>
    //       <p>
    //         You can trust me to take a designer's PSD and quickly & accurately
    //         convert it into a webpage that is a pixel-perfect match.
    //       </p>
    //     </div>
    //     {/* <div className="first-trio">
    //     <img alt="HTML Icon " src={HTMLIcon} />
    //     <h6>Dynamic React Apps</h6>
    //     <p>
    //       My focus is writing clean, well formatted, semantic HTML5 by hand to
    //       make sure that the content is easy to read, easy to collaborate,
    //       trouble-shoot and accessible.
    //     </p>
    //   </div> */}
    //   </div>
    // </section>
  );
};

export default Skills;
